import React, { forwardRef } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const ProductThumbnail = forwardRef( ( props,ref ) => {

	const { isSelected, image, index, handleThumbnailClick } = props

	return (
		<li className="product-images__slide" ref={ ref }>
			<button
				className={`product-images__thumbnail-container ${ isSelected ? 'active' : '' }`}
				onClick={ () => handleThumbnailClick( index, image ) }>
				<GatsbyImage image={ image.localFile.childImageSharp.gatsbyImageData } className="product-images__thumbnail-image" alt="" />
				{/* <GatsbyImage image={ image.localFile.childImageSharp.gatsbyImageData } className="product-images__thumbnail-image" objectFit="contain" alt="" /> */}
			</button>
		</li>
	)
} )

export default ProductThumbnail