import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import ProductRibbon from '../ProductRibbon/ProductRibbon'
import ProductThumbnails from './ProductThumbnails'

export default function ProductImages( props ) {

	const { images, productInStock } = props

	const [ state, setState ] = useState({
		mainImage: images[ 0 ],
		currentIndex: 0
	})

	return (
		<div className="product-images">
			<div className="product-images__main">
				<GatsbyImage image={ state.mainImage.localFile.childImageSharp.gatsbyImageData } className="product-images__thumbnail-image active" alt="" />
				<ProductRibbon quantityInStock={ productInStock } />
			</div>

			<ProductThumbnails images={ images } state={ state } setState={ setState } />
		</div>
	)
}

ProductImages.propTypes = {
	images: PropTypes.array,
	productInStock: PropTypes.number
}