import React, { useState, useContext, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { useQuery, gql } from '@apollo/client';
import ReactMarkdown from "react-markdown"

import { CartContext } from '../context/CartContext'

import { formatPrice } from '../helpers/currency'
import { GTAddToCart, GTViewItem } from '../helpers/gtagEvents'

import SEO from "../components/SEO/SEO"
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import ProductImages from '../components/ProductImages/ProductImages'
import RelatedProducts from '../components/RelatedProducts/RelatedProducts'
import ProductTags from '../components/ProductTags/ProductTags'

import {
	RiSubtractLine,
	RiAddLine
} from 'react-icons/ri'

const ProductTemplate = ({ data: { strapi: { product } }, location }) => {

	const productData = product
	const [ quantity, setQuantity ] = useState( 1 );
	const { addToCart } = useContext( CartContext );

	const filterID = { id: product.id }
	
	const productInStock = productData.quantityInStock ? true : false;

	const metaDescription = productData.description.replace(/(\r\n|\n|\r)/gm, " ").substring(0, 170)
	const metaImage = {
		src: productData.images[0].localFile.childImageSharp.gatsbyImageData.images.fallback.src,
		height: 500,
		width: 500,
	}

	let variables = { where: filterID }

	const productQuery = gql`
		query productQuery( $where: JSON ) {
			products( where: $where ) {
				price
				quantityInStock
			}
		}
	`

	let { error, data } = useQuery( productQuery, {
		variables: {
			...variables
		},
		fetchPolicy: "cache-and-network"
	} )

	useEffect(()=> {
		if ( data ) {
			// apollo data - checking to see if price && quantity same - then updating
			const productInfo = data.products[0]
			if ( productInfo.price !== productData.price ) {
				productData.price = productInfo.price
			}
			if ( productInfo.quantityInStock !== productData.quantityInStock ) {
				productData.quantityInStock = productInfo.quantityInStock
			}
		}
	}, [ data, productData ])

	useEffect(() => {
		GTViewItem( productData )
	}, [ productData ] )

	const incrementQuantity = ( increment ) => {
		const incrementedQuantity = quantity + increment;

		if ( incrementedQuantity ) {
			setQuantity( incrementedQuantity );
		}
	}

	const toggleAddToCart = ( productData, quantity ) => {
		addToCart( productData, quantity )
		GTAddToCart( productData, quantity ) // Trigger gtag event
	}

	if ( error ) return <p>Error :(</p>

	return (
		<>
			<SEO
				title={ productData.name }
				description={ metaDescription }
				image={ metaImage }
				pathname={ location.pathname } />
			<Breadcrumb pathname={ location.pathname } />
			<div className="wrapper">

				<div className={`product-template ${ !productInStock ? 'sold-out' : ''}`}>

					<div className="product-template__main-section">
						<div className="product-template__image">
							<ProductImages images={ productData.images } productInStock={ productData.quantityInStock } />
						</div>

						<div className="product-template__info">
							<h1 className="product-template__name">{productData.name}</h1>
							<span className="product-template__price">{ formatPrice( productData.price ) }</span>
							
							<div className="product-template__inputs-container">
								<div className="product-template__quantity-container">
									<button
										className={`product-template__quantity-trigger ${ quantity === 1 || !productInStock ? 'disabled' : '' }`}
										onClick={ () => incrementQuantity( -1 ) }
										aria-label="Decrease Quantity">
										<RiSubtractLine className="product-template__quantity-icon" />
									</button>
									<div className="product-template__quantity-input">
										{ quantity }
									</div>
									<button
										className={`product-template__quantity-trigger ${ quantity === productData.quantityInStock || !productInStock ? 'disabled' : '' }`}
										onClick={ () => incrementQuantity( 1 ) }
										aria-label="Increase Quantity">
										<RiAddLine className="product-template__quantity-icon" />
									</button>
								</div>
								<button
									className={`product-template__button button button--no-margin ${ !productInStock ? 'disabled' : '' }`}
									onClick={ () => toggleAddToCart( productData, quantity ) }>
										Add to bag
								</button>
							</div>

							<div className="product-template__product-info">
								<div className="product-template__meta-info">
									<span className="product-template__meta-title">Availability:</span>
									{ productData.quantityInStock > 3 &&
										<span className="product-template__status product-template__status--in-stock">In Stock</span>
									}
									{ !!productData.quantityInStock && productData.quantityInStock <= 3 &&
										<span className="product-template__status product-template__status--limited">Limited Stock</span>
									}
									{ !productData.quantityInStock &&
										<span className="product-template__status product-template__status--sold-out">Out Of Stock</span>
									}
								</div>

								{ productData.manufacturerPartNumber &&
									<div className="product-template__meta-info">
										<span className="product-template__meta-title">Product Code:</span>
										<span className="product-template__mpn">
											{ productData.manufacturerPartNumber }
										</span>
									</div>
								}
								{ productData.manufacturer &&
									<div className="product-template__meta-info">
										<span className="product-template__meta-title">Manufacturer:</span>
										
										<div className="product-template__manufacturer">
											<Link to={`/products?manufacturer=${ productData.manufacturer.id }`} className="product-template__manufacturer-link">
												{ productData.manufacturer.name }
											</Link>
										</div>
									</div>
								}
								<div className="product-template__description-title">Product Description:</div>
								<ReactMarkdown children={ productData.description } className="product-template__description" />
							</div>

							<div className="product-template__tags">
								{ !!productData.tags.length &&
									<ProductTags tags={ productData.tags } />
								}
							</div>
						</div>
					</div>

					<div className="product-template__sub-section">
						<RelatedProducts product={ productData } id={ productData.id } />
					</div>
				</div>
			</div>
		</>
	)
}

export default ProductTemplate

export const query = graphql`
query ProductTemplateQuery($id: ID!) {
	strapi {
		product(id: $id) {
			id
			name
			price
			description
			weightInKg
			slug
			quantityInStock
			manufacturerPartNumber
			tags {
				id
				name
			}
			categories {
				id
				name
			}
			images {
				url
				id
				localFile {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
			manufacturer {
				name
				id
			}
		}
	}
}
`