import React, { useState, useEffect, useRef, useMemo } from "react"
import { useQuery, gql } from '@apollo/client'

import ContentSlider from '../ContentSlider/ContentSlider'
import ProductCard from '../ProductCard/ProductCard'
import { getFluidImage } from '../../helpers/apollo'
import { PRODUCT_DETAILS } from '../../fragments/fragments'

const RelatedProducts = ( props ) => {

	const { product, id } = props
	const dataRef = useRef()
	const tagIds = product.tags.map( tag => tag.id )
	const categoriesId = useMemo(() => product.categories.map(node => node.id), [product.categories])

	const limit = 8 // number of products wanted to be shown
	const max = 500

	const where = { id_nin: [ id ] , tags_in: tagIds  } // first get related products by tag
	const [ variables, setVarialbles ] = useState({ limit: limit, where: where })
	const [ state, setState ] = useState({ products: [] })
	const [ ids, setIds ] = useState([ id ])

	const productQuery = gql`
		query getRelatedProducts ( $limit: Int, $where: JSON) {
			products ( limit: $limit, where: $where) {
				...productDetails
			}
		}
		${ PRODUCT_DETAILS }
	`
	
	let { error, data } = useQuery( productQuery,
		{
			variables: {
				...variables
			},
			fetchPolicy: "cache-and-network"
		}
	);

	useEffect(() => {

		if ( data ) {
			dataRef.current = JSON.parse(JSON.stringify(data));
			let updatedProducts = state.products

			dataRef.current.products.forEach( product => {

				if ( ids.includes( product.id ) ) return

				product.images[0].localFile = { childImageSharp : { gatsbyImageData : getFluidImage( product, max, 0 ) } }

				if ( product.images.length > 1 ) {
					product.images[1].localFile = { childImageSharp : { gatsbyImageData : getFluidImage( product, max, 1 ) } }
				}

				ids.push( product.id )
				updatedProducts.push( product )
			})

			if ( updatedProducts.length < limit ) {
				if ( variables.where.tags_in ) {
					setVarialbles( { limit: limit, where: { id_nin: ids, categories_in: categoriesId } } )
					
				} else if ( variables.where.categories_in ) {
					setVarialbles( {limit: limit, where: { id_nin: ids }})
				}
				setState({ products: updatedProducts })
			} else {
				updatedProducts.length = limit
				setState({ products: updatedProducts })
			}

			setIds( ids )
		}
	}, [ data, ids, state.products, variables, categoriesId ])

	if ( error ) return null;

	return (
		<>
			{ state && !!state.products.length &&
				<div className="related-products">
					<h2 className="related-products__heading">Related Products</h2>
					<ContentSlider itemsDesktop={ 4 } itemsTablet={ 3 } itemsMobile={ 1 }>
						{ state.products.map( ( node , i ) => (
							<React.Fragment key={ i }>
								<ProductCard product={ node } showCartButton={ false } />
							</React.Fragment>
						) ) }
					</ContentSlider>
				</div>
			}
		</>
	)
}

export default RelatedProducts