import React, { useEffect, useState, useRef } from 'react'

import ProductThumbnail from './ProductThumbnail'

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'

export default function ProductThumbnails ( props ) {

	const RESIZE_DEBOUNCE = 150;
	const thumbnailsRef = useRef()

	const { images, state, setState } = props

	const [ slideWidth, setSlideWidth ] = useState()
	const [ activeSlide, setActiveSlide ] = useState(0)
	const [ slidePos, setSlidePos ] = useState( state.currentIndex )
	const totalImages = images.length
	const itemsPerPage = 4

	useEffect( () => {

		if ( thumbnailsRef ) {
			setSlideWidth( thumbnailsRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setSlideWidth( thumbnailsRef.current.offsetWidth ), RESIZE_DEBOUNCE );
			// on resize reset slider
			setActiveSlide( 0 )
			setSlidePos( 0 )
			setState({ mainImage: images[ 0 ], currentIndex: 0 })
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};

	}, [ setState, images ] )

	const scrollNext = () => {
		const currentIndex = state.currentIndex + 1
		const slidesLeft = totalImages - currentIndex

		if ( currentIndex + 1 >= itemsPerPage && slidesLeft  > 1 ) {
			const slidesToScroll = currentIndex + 1 - itemsPerPage
			setSlidePos( ( slidesToScroll + 1 ) * slideWidth )
			setActiveSlide( currentIndex + 1 )
		}

		setState({
			mainImage: images[ state.currentIndex + 1 ],
			currentIndex: state.currentIndex + 1
		})
	}

	const scrollPrev = () => {
		const currentIndex = state.currentIndex

		if ( activeSlide - currentIndex >= 1 ) {
			const slidesToScroll = slidePos / slideWidth
			setSlidePos( ( slidesToScroll - 1 ) * slideWidth )
			setActiveSlide( currentIndex + 1 )
		}

		setState({
			mainImage: images[ state.currentIndex - 1 ],
			currentIndex: state.currentIndex - 1
		})
	}

	const handleThumbnailClick = ( index, image ) => {
		const slidesLeft = totalImages - index

		if ( index + 1 >= itemsPerPage && slidesLeft > 1 && index > state.currentIndex ) { // Next
			const slidesToScroll = index + 1 - itemsPerPage
			setSlidePos( ( slidesToScroll + 1 ) * slideWidth )
			setActiveSlide( index + 1 )
		} else if ( slidesLeft - index >= 1 ) { // Previous
			const slidesToScroll = slidePos / slideWidth
			setSlidePos( ( slidesToScroll - 1 ) * slideWidth )
			setActiveSlide( index + 1 )
		}

		setState({ mainImage: image, currentIndex: index })
	}

	const isSlideSelected = slideIndex => slideIndex === state.currentIndex

	return (
		<div className="product-images__thumbnails-wrapper">

			<div className="product-images__container">
				<div className="product-images__wrapper">
					<ul
						className={`product-images__inner-wrapper block-list-4 ${ totalImages < itemsPerPage ? 'product-images__inner-wrapper--center' : '' }`}
						style={{ left: `-${ slidePos }px` }}>
							{ images.map( ( image, index ) => (
								<ProductThumbnail
									image={ image }
									key={ index }
									ref={ thumbnailsRef }
									isSelected={ isSlideSelected( index ) }
									index={ index }
									handleThumbnailClick={ handleThumbnailClick } />
								
							) ) }
					</ul>
				</div>
			</div>

			<RiArrowLeftSLine
				className={`product-images__button product-images__button--prev ${ !state.currentIndex ? 'disabled' : '' }`}
				onClick={ () => scrollPrev() }
				/>
				
			<RiArrowRightSLine
				className={`product-images__button product-images__button--next ${ state.currentIndex + 1 === totalImages ? 'disabled' : '' }`}
				onClick={ () => scrollNext() }
				/>
		</div>
	)
}