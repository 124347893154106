import React from 'react'
import { Link } from "gatsby"

export default function ProductTags( props ) {

	const { tags } = props;

	return (
		<div className="product-tags">
			<div className="product-tags__header">Tags:</div>
			<ul className="product-tags__list">
				{ tags.map( tag => (
					<li className="product-tags__tag" key={ tag.id }>
						<Link to={`/products?tag=${ tag.id }`} className="product-tags__link">
							{ tag.name }
						</Link>
						
					</li>
				 ) ) }
			</ul>
		</div>
	)
}